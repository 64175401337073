html,
body {
  margin: 0;
  background-color: #000;
  font-family: Kanit, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
a {
  text-decoration: none;
}
img {
  user-select: none;
}

.ant-modal-root {
  .app-confirm-wrap {
    backdrop-filter: blur(4px);
  }
  .ant-modal {
    .ant-modal-content {
      background-color: rgba(45, 51, 110, 1);
      border: 2px solid rgba(255, 255, 255, 0.2);
      .ant-modal-confirm-title {
        color: rgba(250, 190, 75, 1);
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }
      .ant-modal-body {
        padding-bottom: 30px;
      }
      .app-confirm-btn {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
}
